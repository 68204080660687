<template>
  <div class="potential" ref="potential">
    <rx-nav-bar title="选择城市" androidOrIOSFlag="true"></rx-nav-bar>
    <div class="searchBody">
      <van-search class="searchInput" v-model="searchValue" placeholder="搜索小区,商圈" @input="search"/>
    </div>
    <div class="panel">
      <div class="title">
        <p>当前城市</p>
      </div>
      <div class="info">
        <div class="tag" v-text="curCityName"></div>
        <div class="replay" @click="replay">
          <van-icon name="replay" color="rgba(255, 93, 59, 1)" />
          <span>重新定位</span>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="title">所有城市</div>
      <div class="list">
        <p :class="{'active':item.isChoose}" v-for="item in cityList" :key="item.id" v-text="item.cityName" @click="selectCity(item)"></p>
      </div>
    </div>


  </div>
</template>
<script>
  import rxNavBar from "../../components/rongxun/rx-navBar/navBar"
  import {
    Search,
    List,
    Cell,
    Icon
  } from "vant";
   // import android from "../util";
  // import config from "../config";
  import {
    queryCityList
  } from "../../getData/getData";
  import {
      responseUtil,
      checkAndroid,
      checkIOS
  } from "../../libs/rongxunUtil";

  function selectCityApp(cityId,cityName){
      //alert("城市:"+msg);
    console.log(cityId)
    console.log(cityName)
      window.himi.selectCityApp(cityId,cityName);
      window.himi.closeWindow();
  }

  export default {
    components: {
      [Search.name]: Search,
      [List.name]: List,
      [Cell.name]: Cell,
      [Icon.name]: Icon,
      rxNavBar
    },
    data() {
      return {
        searchValue: '',
        cityList: [],
        curCityName:'',
        curCityId:''
      }
    },
    methods: {
      init() {
        let that = this
        queryCityList({}).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.list = response.data.data.cityList
            that.cityList = that.list
          })
        })
      },
      selectCity(item){
        this.curCityName = item.cityName;
        this.curCityid = item.city_id;
        let city = {}
        city.cityName = item.cityName
        city.city_id = item.city_id
        localStorage.setItem('searchKeyword', JSON.stringify(city))
        this.$router.go(-1)
        this.selectCityApp();//调用app方法
      },
      selectCityApp(){
          //APP传值*******begin*********
          if(checkAndroid()){
              selectCityApp(this.curCityid,this.curCityName);
          }else if(checkIOS()){
            console.log("对ios传参");
            let cityInfo ={
              cityName : this.curCityName,
              cityId : this.curCityid,
            }
            window.webkit.messageHandlers.selectCityApp.postMessage({body: cityInfo});
          }
          //APP传值*******end*********
      },
      autoGetCity(){
        let that = this
        var geolocation= new BMap.Geolocation();
            geolocation.getCurrentPosition(function(r){
                if(this.getStatus()==BMAP_STATUS_SUCCESS){
                    // var lat=r.address.lat//当前经度
                    // var lng=r.address.lng//当前纬度
                    // var province=r.address.province //返回当前省份
                    var city=r.address.city//返回当前城市
                    that.curCityName = city
                    // that.selectCityApp()//掉用app方法
                }
            })
      },
      replay(){
        setTimeout(this.autoGetCity()
        ,1);
      },
      selectCurCity(value){
        if(this.cityList!=''){
          this.cityList.forEach(item => {
            if(value.indexOf(item.cityName) != -1){
              item.isChoose = true
            }else{
              item.isChoose = false
            }
          })
        }

      },
      search(value){
        this.cityList = this.list.filter(item =>{
          return item.cityName.indexOf(value) != -1
        })
      },

      /************测试安卓往VUE调用并传参 begin*****************/
      testAndroid(test) {
          // 将Android传递过来的数据解析
          // var data = JSON.parse(locationData);
          // setStore('lat', data.latitude);
          // setStore('lng', data.longitude);
          // alert(test);
      }
        /************测试安卓往VUE调用并传参 end*****************/
    },
    mounted() {
      this.init();
      this.autoGetCity();

      /************测试安卓往VUE调用并传参 begin*****************/
      window.testAndroid = this.testAndroid;  // 挂载到window中
      /************测试安卓往VUE调用并传参 end*****************/
    },
    watch:{
      "curCityName":function(newVal){
        this.selectCurCity(newVal)
      }
    }
  }
</script>
<style lang="less" scoped>
  @radius: 8px;
  .potential {
    padding:0 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    height: 100vh;
    p {
      margin: 0;
    }

    .searchBody {
      margin-top: 30px;
      .searchInput {
        width: 335px;
        height: 36px;
        border-radius: 18px;
        background-color: rgba(244, 244, 244, 1);
        overflow: hidden;
      }
    }

    .panel {
      margin-top: 20px;
      font-size: 14px;

      .title {
        font-weight: bolder;
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        text-align: left;
      }

      .info {
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tag {
          text-align: center;
          line-height: 40px;
          border-radius: @radius;
          font-size: 15px;
          width: 70px;
          height: 40px;
          background-color: rgba(244, 244, 244, 1);
          color: rgba(34, 34, 34, 1);
        }

        .replay {
          color: rgba(255, 93, 59, 1);
          font-size: 12px;
        }
      }

      .list {
        margin-top: 10px;

        &>p {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          text-align: left;
          margin-left: 5px;
          color: rgba(102, 102, 102, 1);
          &.active{
            color: rgba(255, 93, 59, 1);
          }
        }
      }

    }
  }
</style>
